import initSqlJs from 'sql.js'
import axios from 'axios'

// const publicPath = process.env.NODE_ENV === 'production'? '/kobo-note-reader/' : '/'
const publicPath = '/'

export default {
    async loadDemo(databaseFile, absolutePath) {
        const url = absolutePath ? databaseFile : publicPath + databaseFile
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        return response.data
        // return await new Response(response.data).arrayBuffer();
    },
    async conn(arrayBuffer) {
        const wasmFile = publicPath + "sql-wasm.wasm";
        const SQL = await initSqlJs({
            locateFile: () => `${wasmFile}`
        })

        try {
            return new SQL.Database(new Uint8Array(arrayBuffer));
        } catch {
            console.error('failed to read db')
            return {};
        }
    },
    async get(databaseFile) {
        const wasmFile = publicPath + "sql-wasm.wasm";
        const SQL = await initSqlJs({
            locateFile: () => `${wasmFile}`
        })

        try {
            const response = await axios.get(publicPath + databaseFile, { responseType: 'arraybuffer' });
            return new SQL.Database(new Uint8Array(response.data));
        } catch {
            console.error('failed to read db: ' + databaseFile)
            return {};
        }
    }
}