//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Instruction",
  data: function () {
    return {
      tab: "mac",
    };
  },
  methods: {
    onCopy: function (e) {
      console.debug(e.text);
      this.$q.notify({
        type: "positive",
        message: "Path copied!",
        caption: "Ctrl + V to paste.",
      });
    },
  },
};
