//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainLayout from "../layouts/Main.vue";
import loaddb from '../api/loaddb'
import Instruction from "../components/Instruction"

export default {
  name: "Home",
  mounted() {
    if(this.$root.$data.kobo) {
      this.db = this.$root.$data.kobo
      this.dbReady = true;
    }
  },
  data () {
    return {
      tab: 'upload',
      isLoading: false,
      dbUrl: '',
      logo: '📕',
      logos: ['📕', '📘', '📒', '📓', '📗', '📙', '😴'],
      dbReady: false,
      base64Images: [],
      files: null,
      uploadProgress: [],
      uploading: null,
      demoDb: null
    }
  },
  computed: {
    shelfReady() {
      return this.dbReady && (!!this.files || !!this.demoDb)
    }
  },
  methods: {
    shuffleLogo() {
      this.logo = this.logos[Math.floor(Math.random() * this.logos.length)]
    },
    async loadDemoDb(dbFilePath, isAbsolute) {
      this.isLoading = true;
      this.demoDb = await loaddb.loadDemo(dbFilePath, isAbsolute)
      this.$root.$data.kobo = await loaddb.conn(this.demoDb);
      this.dbReady = true;
      this.isLoading = false;
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.onloadend = () => {
        console.debug('loaded')
        this.base64Images.push({
          src: reader.result,
          filename: file.name
        });
      }
      reader.readAsDataURL(file);
    },
    async loadDatabase(file) {
      this.dbReady = false;
      var reader = new FileReader();
      reader.onloadend = async () => {
        this.$root.$data.kobo = await loaddb.conn(reader.result);
        console.debug('loaded')
        this.dbReady = true;
      }
      reader.readAsArrayBuffer(file);
    }
  },
  watch: {
    async files(newVal) {
      if(newVal && newVal !== '(cached)') {
        await this.loadDatabase(newVal);
      }
    }
    // files(newVals) {
    //   this.base64Images = [];
    //   if(newVals && newVals.length > 0) {
    //     newVals.forEach(file => {
    //       console.debug('pushfile')
    //       this.getBase64(file);
    //     });
    //   }
    // }
  },
  components: {
    MainLayout,
    Instruction
  },
};
